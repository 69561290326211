
  import React from 'react';
  import { getWidgetWrapper } from '@wix/yoshi-flow-editor/runtime/esm/WidgetWrapper.js';
  import Widget from '/home/builduser/work/a037be9d14b7f75e/packages/communities-blog-ooi/src/components/Archive/Widget/index.tsx';

  import {
    withStyles
  } from '@wix/native-components-infra';

  
var initI18n = null;

  
import { createExperiments, createWidgetExperiments } from '@wix/yoshi-flow-editor/runtime/esm/experiments';
    

  
var I18nextProvider = React.Fragment;


      import { PureExperimentsProvider } from '@wix/yoshi-flow-editor';
      var ExperimentsProvider = React.Fragment;
      

import { BILoggerProvider } from '@wix/yoshi-flow-editor/runtime/esm/react/BILogger/BILoggerProvider';

import { FedopsLoggerProvider } from '@wix/yoshi-flow-editor/runtime/esm/react/FedopsLoggerProvider';

import { HttpProvider } from '@wix/yoshi-flow-editor';

  import { TPAComponentsProvider } from 'wix-ui-tpa';

var BaseUiEnvironmentProviderWrapper = React.Fragment;

var providers = {
  I18nextProvider,
  ExperimentsProvider,
  PureExperimentsProvider,
  BILoggerProvider,
  FedopsLoggerProvider,
  HttpProvider,
  TPAComponentsProvider,
  BaseUiEnvironmentProviderWrapper,
}


  var stylesParams = null;

  var sentryConfig = {
      DSN: 'https://2062d0a4929b45348643784b5cb39c36@sentry.wixpress.com/1643',
      id: '2062d0a4929b45348643784b5cb39c36',
      projectName: 'communities-blog-ooi',
      teamName: 'blogas',
      
    };

  var translationsConfig = {"enabled":false,"icuEnabled":false};

  var UserComponent = getWidgetWrapper({
      initI18n,
      withStylesHoc: withStyles,
      createExperiments,
      createWidgetExperiments,
      providers,
    }, Widget, {
    sentryConfig,
    translationsConfig,
    stylesParams,
    componentId: '2f3d2c69-2bc4-4519-bd72-0a63dd92577f',
    name: 'Archive',
    withErrorBoundary: true,
    localeDistPath: 'assets/locales',
  });

  
    import { hot } from '@wix/component-hot-loader';
    UserComponent = hot(module, UserComponent);
  

  const loadChunks = Widget.loadChunks;

  export default {
    
          loadableReady: process.env.browser ? require("@wix/yoshi-flow-editor/loadable").loadableReady : null,
          chunkLoadingGlobal: process.env.chunkLoadingGlobal,
          
    component: UserComponent,
    loadChunks
  };
